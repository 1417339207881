<template>
  <div id="recordDialog">
    <el-dialog
      title="订单明细"
      :visible.sync="show"
      width="720px"
      @open="getRecord"
    >
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="parkingLotName" label="停车场" align="center">
        </el-table-column>
        <el-table-column prop="parkingTime" label="停车时长" align="center">
        </el-table-column>
        <el-table-column prop="arrivalTime" label="进场时间" align="center">
        </el-table-column>
        <el-table-column prop="departureTime" label="出场时间" align="center">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      parkingRecordId: "",
      table: {
        tableData: [],
        loading: false,
      },
    };
  },
  methods: {
    async getRecord() {
      try {
        this.table.loading = true;

        let res = await this.$http.post(
          `/parking/record/list/detail/${this.parkingRecordId}`
        );
        if (res.code === 0) {
          for (let item of res.data) {
            item.departureTime = item.departureTime
              ? this.$moment(item.departureTime).format("yyyy-MM-DD HH:mm:ss")
              : "--";
            item.arrivalTime = item.arrivalTime
              ? this.$moment(item.arrivalTime).format("yyyy-MM-DD HH:mm:ss")
              : "--";
          }
          this.table.tableData = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#recordDialog {
  .el-dialog {
    max-height: calc(100vh - 30vh);
    overflow-y: auto;
  }
}
</style>
